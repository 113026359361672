require("./component/AmplitudeEnvelope.js");
require("./component/Analyser.js");
require("./component/Channel.js");
require("./component/Compressor.js");
require("./component/CrossFade.js");
require("./component/Envelope.js");
require("./component/EQ3.js");
require("./component/FeedbackCombFilter.js");
require("./component/FFT.js");
require("./component/Filter.js");
require("./component/Follower.js");
require("./component/FrequencyEnvelope.js");
require("./component/Gate.js");
require("./component/LFO.js");
require("./component/Limiter.js");
require("./component/LowpassCombFilter.js");
require("./component/Merge.js");
require("./component/Meter.js");
require("./component/MidSideCompressor.js");
require("./component/MidSideMerge.js");
require("./component/MidSideSplit.js");
require("./component/Mono.js");
require("./component/MultibandCompressor.js");
require("./component/MultibandSplit.js");
require("./component/Panner.js");
require("./component/Panner3D.js");
require("./component/PanVol.js");
require("./component/ScaledEnvelope.js");
require("./component/Solo.js");
require("./component/Split.js");
require("./component/Volume.js");
require("./component/Waveform.js");
require("./control/CtrlInterpolate.js");
require("./control/CtrlMarkov.js");
require("./control/CtrlPattern.js");
require("./control/CtrlRandom.js");
require("./core/AudioNode.js");
require("./core/Buffer.js");
require("./core/Buffers.js");
require("./core/Bus.js");
require("./core/Clock.js");
require("./core/Context.js");
require("./core/Delay.js");
require("./core/Draw.js");
require("./core/Emitter.js");
require("./core/Gain.js");
require("./core/IntervalTimeline.js");
require("./core/Listener.js");
require("./core/Master.js");
require("./core/Offline.js");
require("./core/OfflineContext.js");
require("./core/Param.js");
require("./core/Timeline.js");
require("./core/TimelineState.js");
require("./core/Transport.js");
require("./core/TransportEvent.js");
require("./core/TransportRepeatEvent.js");
require("./effect/AutoFilter.js");
require("./effect/AutoPanner.js");
require("./effect/AutoWah.js");
require("./effect/BitCrusher.js");
require("./effect/Chebyshev.js");
require("./effect/Chorus.js");
require("./effect/Convolver.js");
require("./effect/Distortion.js");
require("./effect/Effect.js");
require("./effect/FeedbackDelay.js");
require("./effect/FeedbackEffect.js");
require("./effect/Freeverb.js");
require("./effect/JCReverb.js");
require("./effect/MidSideEffect.js");
require("./effect/Phaser.js");
require("./effect/PingPongDelay.js");
require("./effect/PitchShift.js");
require("./effect/Reverb.js");
require("./effect/StereoEffect.js");
require("./effect/StereoFeedbackEffect.js");
require("./effect/StereoWidener.js");
require("./effect/StereoXFeedbackEffect.js");
require("./effect/Tremolo.js");
require("./effect/Vibrato.js");
require("./event/Event.js");
require("./event/Loop.js");
require("./event/Part.js");
require("./event/Pattern.js");
require("./event/Sequence.js");
require("./instrument/AMSynth.js");
require("./instrument/DuoSynth.js");
require("./instrument/FMSynth.js");
require("./instrument/Instrument.js");
require("./instrument/MembraneSynth.js");
require("./instrument/MetalSynth.js");
require("./instrument/Monophonic.js");
require("./instrument/MonoSynth.js");
require("./instrument/NoiseSynth.js");
require("./instrument/PluckSynth.js");
require("./instrument/PolySynth.js");
require("./instrument/Sampler.js");
require("./instrument/Synth.js");
require("./shim/AnalyserNode.js");
require("./shim/AudioBuffer.js");
require("./shim/AudioContext.js");
require("./shim/BufferSourceNode.js");
require("./shim/ConstantSourceNode.js");
require("./shim/OfflineAudioContext.js");
require("./shim/OscillatorNode.js");
require("./shim/StereoPannerNode.js");
require("./shim/WaveShaperNode.js");
require("./signal/Abs.js");
require("./signal/Add.js");
require("./signal/AudioToGain.js");
require("./signal/EqualPowerGain.js");
require("./signal/GainToAudio.js");
require("./signal/GreaterThan.js");
require("./signal/GreaterThanZero.js");
require("./signal/Modulo.js");
require("./signal/Multiply.js");
require("./signal/Negate.js");
require("./signal/Normalize.js");
require("./signal/Pow.js");
require("./signal/Scale.js");
require("./signal/ScaleExp.js");
require("./signal/Signal.js");
require("./signal/SignalBase.js");
require("./signal/Subtract.js");
require("./signal/TickSignal.js");
require("./signal/TransportTimelineSignal.js");
require("./signal/WaveShaper.js");
require("./signal/Zero.js");
require("./source/AMOscillator.js");
require("./source/BufferSource.js");
require("./source/FatOscillator.js");
require("./source/FMOscillator.js");
require("./source/GrainPlayer.js");
require("./source/Noise.js");
require("./source/OmniOscillator.js");
require("./source/Oscillator.js");
require("./source/OscillatorNode.js");
require("./source/Player.js");
require("./source/Players.js");
require("./source/PulseOscillator.js");
require("./source/PWMOscillator.js");
require("./source/Source.js");
require("./source/TickSource.js");
require("./source/UserMedia.js");
require("./type/Frequency.js");
require("./type/Midi.js");
require("./type/Ticks.js");
require("./type/Time.js");
require("./type/TimeBase.js");
require("./type/TransportTime.js");
require("./type/Type.js");
module.exports = require("./core/Tone");
